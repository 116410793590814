* {
    scroll-behavior: smooth;
}

.shadowHeader {
    -webkit-box-shadow: 0 0 .625rem rgba(0, 0, 0, .2);
    box-shadow: 0 0 .625rem rgba(0, 0, 0, .2)
}


.scrollMargin {
    scroll-margin: 5rem;
    scroll-behavior: smooth;
}

.preline {
    white-space: pre-line;
}

*[tabindex]:focus {
    outline: 0;
    border: .0625rem solid #f36633;
    -webkit-box-shadow: 0 0 0 .25rem rgba(243, 102, 51, .2);
    box-shadow: 0 0 0 .25rem rgba(243, 102, 51, .2)
}


/* .target {
    -moz-transition: -moz-transform .5s;
    -o-transition: -o-transform .5s;
    -webkit-transition: -webkit-transform .5s;
    -moz-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    -webkit-transform-origin: top;
    transform-origin: top;
    transition: transform .5s;
} */


.h-0 {
    height: 0;
    overflow: hidden;
}
